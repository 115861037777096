<template>
    <Card :titulo="reporte.area" :acciones="{activar_tool:false, nombre: '', icono:'mdi-close'}">
        <template v-slot:contenido>
            <v-row v-for="(info,index) in reporte.datos" :key="index">
                <v-col>
                     <p>
                        Estado: {{info.estado}}
                        
                    </p>
                    <p>Cantidad: {{info.cantidad}}</p>
                    <v-divider></v-divider>
                </v-col>
               
            </v-row>
            
        </template>
    </Card>
</template>
<script>
export default {
    props: {
        reporte: {
            type: Object,
            required: true,
        }
    },
    data(){
        return {

        }
    }
}
</script>