<template>
  <v-sheet color="grey lighten-4">
    <v-container>
      <Toolbar :titulo="$route.params.id" :color="'indigo darken-4'" 
          :accion="'Hacer reporte'" 
          :icono="'mdi-file-pdf-outline'" 
          @activar="realizar_reporte(reporte)"
          >
        <template v-slot:accion_retroceder>
          <v-btn fab small text @click="$router.go(-1)">
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
        </template>
      </Toolbar>
    
      <v-row>
        <v-col>
          <v-sheet
            color="grey lighten-4"
            elevation=""
            rounded=""
            class="mt-3 mx-auto"
            min-height="500"
            max-width="1000"
          >
            <v-row>
              <Load class="mx-5" :load="load" />
            </v-row>
            <v-row
              align="center"
              justify="center"
            >
              <v-col>
                <v-row>
                  <v-col cols="12" sm="5">
                    <v-row>
                      <v-autocomplete v-model="reporte.tipo_documental" 
                          class="mt-3"
                          label="Selecciona el tipo documental" 
                          return-object
                          prepend-icon="mdi-book-edit-outline"
                          :item-text="'nombre'"
                          :items="tipos_documentales"
                          filled rounded dense >

                      </v-autocomplete>
                    </v-row>
                    
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-row>
                      <v-col>
                        <DatePicker @fecha="reporte.fecha_inicial=$event" :label="'Fecha de inicio'" />
                      </v-col>
                      <v-col>
                        <DatePicker  @fecha="reporte.fecha_final=$event" :label="'Fecha de finalizacion'" />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="8" class="mx-auto" sm="1">
                    <v-row class="mt-5">
                      <v-btn 
                        @click="realizar_reporte(reporte)"
                        :disabled="validacion" style="color:white" block color="red darken-4"> 
                        <v-icon>mdi-file-pdf-outline</v-icon> 
                        Reporte 
                      </v-btn>
                    </v-row>
                  </v-col>  
                </v-row>
                <v-row v-if="reporte_por_areas.length>0">
                  <v-col cols="12" sm="6">
                     <Toolbar :titulo="'Reporte por areas'" :activar_tool="false" />
                    <v-row>
                       <v-col cols="6" v-for="(reporte,index) in reporte_por_areas" :key="index">
                          <ReportePorArea :reporte="reporte" />
                        </v-col>
                    </v-row>
                    
                  </v-col>
                  <v-col cols="12" sm="6">
                    <Toolbar :titulo="'Graficas de actividades pendientes'" :activar_tool="false" />
                    <v-row>
                      <v-col class="mx-auto" cols="10">
                        <apexchart
                        class="mx-auto"
                          width="100%"
                          
                          type="donut"
                          :options="chartOptions"
                          :series="seriesC"
                        ></apexchart>
                      </v-col>
                        
                     
                    </v-row>
                    <v-row>
                      <v-col class="mx-auto" cols="10">
                        <apexchart
                            class="mx-auto"
                            width="100%"
                            type="bar"
                            :options="options"
                            :series="series"
                          ></apexchart>
                      </v-col>
                       
                          
                       
                    </v-row>
                  </v-col>
                </v-row>
               
                
              </v-col>
              
              
            </v-row>
            
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import DatePicker from "../../components/celulas/DatePicker.vue"
import ReportePorArea from "../../components/admin/ReportesPorArea.vue"

export default {
  components: {
    apexchart: VueApexCharts,
    DatePicker,
    ReportePorArea,
  },
  data() {
    return {
      options: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: "series-1",
          data: [],
        },
      ],
      chartOptions: {
            chart: {
              width: 380,
              height: 380,
              type: 'donut',
              dropShadow: {
                enabled: true,
                color: '#111',
                top: -1,
                left: 3,
                blur: 3,
                opacity: 0.2
              }
            },
            stroke: {
              width: 0,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    total: {
                      showAlways: true,
                      show: true
                    }
                  }
                }
              }
            },
            labels: [],
            dataLabels: {
              dropShadow: {
                blur: 3,
                opacity: 0.8
              }
            },
      },
      seriesC: [],
      
      tipos_documentales: [],
      reporte: {
        fecha_inicial:new Date().toISOString().substr(0, 10) ,
        fecha_final: new Date().toISOString().substr(0, 10) ,
        tipo_documental: null
      },
      reporte_por_areas: [],
      load: false
    };
  },
  created(){
    this.consultar_tipos_documentales()
  },
  computed:{
    validacion(){
      if( (this.reporte.fecha_inicial!="" || this.reporte.fecha_inicial!=null) &&  
          (this.reporte.fecha_final!="" || this.reporte.fecha_final!=null) &&
          (this.reporte.tipo_documental!=null)
        ){
          return false
      }
      return true
     
    }
  },
  methods: {
    async realizar_reporte(reporte){
      this.reporte_por_areas = []
      this.load = true
      let estados = [
        'Pendiente',
        'Aceptado',
        'Rechazado',
        'Respondido'
      ]
      try {
        console.log(reporte)
        let areas = await this.$store.dispatch('llamado_get',{
          url:"https://audidoc.educandote.co/parse/classes/prueba01Sector",
          tipo_header: "parse",

        })
        console.log(areas)

        let contador = JSON.stringify('1')
        contador = encodeURIComponent(contador)   
        let limite = JSON.stringify('1') 
        limite = encodeURIComponent(limite)
        let buscar
        let reporte_por_areas = [] 
        for (let index = 0; index < areas.data.results.length; index++) {

          reporte_por_areas.push({
            area: areas.data.results[index].nombre,
            datos: []
          })
         this.chartOptions.labels.push(areas.data.results[index].nombre)
         this.options.xaxis.categories.push(areas.data.results[index].nombre)
        

          
          for (let index2 = 0; index2 < estados.length; index2++) {

            buscar = JSON.stringify({"fecha_asignacion_int": { "$gte": Date.parse(new Date(reporte.fecha_inicial))  ,
                                                            "$lte": Date.parse(new Date(reporte.fecha_final+' '+'23:59' )) 
                                                          },
                                   "area_id": areas.data.results[index].objectId,
                                    "estado": estados[index2],
                                    "tipo_documental": this.reporte.tipo_documental.nombre,
                                          
                                  })
              try {
                let reporte_por_area = await this.$store.dispatch('llamado_get',{
                  url:"https://audidoc.educandote.co/parse/classes/prueba01casos?where="+buscar+"&count="+contador+"&limit="+limite,
                  tipo_header: "parse"
                })

                if(estados[index]=='Pendiente'){
                   this.seriesC.push(reporte_por_area.data.count)
                   this.series[0].data.push(reporte_por_area.data.count)
                }
                console.log(reporte_por_area)
                reporte_por_areas[index].datos.push({
                  estado: estados[index2],
                  cantidad: reporte_por_area.data.count
                })
                
              } catch (error) {
                console.log(error)
              }
          }
          
          
        }
        console.log(reporte_por_areas)
        this.reporte_por_areas = [...reporte_por_areas]

      } catch (error) {
        console.log(error)
      }
      this.load = false
    },
    async consultar_tipos_documentales(){
      try {
        let tipos_documentales = await this.$store.dispatch('llamado_get',{
          url: "https://audidoc.educandote.co/parse/classes/V1_tiposDocumentales",
          tipo_header: "parse"
        })
        console.log(tipos_documentales)
        this.tipos_documentales = [...tipos_documentales.data.results]
      } catch (error) {
        console.log(error)
      }
    },
    updateChart() {
      const max = 90;
      const min = 20;
      const newData = this.series[0].data.map(() => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      });
      // In the same way, update the series option
      this.series = [
        {
          data: newData,
        },
      ];
    },


    //Reporte de archivadores indica

    async reporte_archivador(){
     
      let pagina = 1
      // let cantidad = 0
      let cantidad = await this.consultar(pagina)
      pagina++
      for (let index = 0; index < cantidad.documentos  ; index++) {
        
        let response = await this.consultar(pagina);
          if(response.sucess){
            cantidad.imagenes = cantidad.imagenes + response.imagenes
            cantidad.documentos = response.documentos
          }else{
            console.log(response.error)
          }
        pagina++
      }
      console.log(cantidad)
    },
    async consultar(pagina){
      let fecha ={
        inicial: Date.parse(new Date('2021-10-20')),
        final: Date.parse(new Date('2021-11-12'))
      };
     
       try {
          let response = await this.$store.dispatch('llamado_get',{
            url:"https://audidoc.educandote.co/types/collections/pruebaResolucion/documents/search?q=*&query_by=Numero_consecutivo&filter_by=fecha_creacion:["+fecha.inicial+".."+fecha.final+"]&per_page=250&page="+pagina,
            tipo_header: 'indexador'
          })
          let cantidad = {
            documentos: parseInt(response.data.found/250)+1 ,
            imagenes: 0,
            sucess: true,
            error: false,
          }
          for (let index = 0; index < response.data.hits.length; index++) {
             for (let index2 = 0; index2 < response.data.hits[index].document.carpetas.length; index2++) {
                let carpetas = JSON.parse(response.data.hits[index].document.carpetas[index2])
                cantidad.imagenes = cantidad.imagenes + carpetas.archivos.length
              
            }
          }
        return cantidad
       } catch (error) {
         console.log(error)
         let cantidad = {
           documentos: -1 ,
           imagenes: -1,
           sucess: false,
           error: error.message
         }
         return cantidad
       }
       
    }
  },
};
</script>

<style lang="scss" scoped>
</style>